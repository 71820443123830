import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Content from "../components/content"
import Intro from "../components/intro"

import * as styles from "./about.module.scss"

const Profile = ({ data }) => {
  return (
    <section className={styles.profile}>
      <div className={styles.dots}>
        <GatsbyImage
          width={350}
          image={data.path.childImageSharp.gatsbyImageData}
          alt={data.alt}
        />
      </div>
    </section>
  )
}

const AboutPage = ({ data }) => {
  const yml = data.allDataYaml.edges[0].node.about
  return (
    <Layout>
      <Content>
        <div className={styles.about}>
          <Profile data={yml} />
          <section className={styles.details}>
            <Intro title="About" description={yml.intro}>
              <p className={styles.paragraph}>{yml.paragraph}</p>
              <a href="./dimpram_cv_2023_latest.pdf">View resume</a>
            </Intro>
          </section>
        </div>
      </Content>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allDataYaml(sort: { fields: about___alt }) {
      edges {
        node {
          about {
            alt
            intro
            paragraph
            path {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  placeholder: BLURRED
                  width: 500
                  height: 500
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  }
`
